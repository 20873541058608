import React from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { format } from "date-fns";

import Loader from "../../Loader";
import { getDeviceType } from "../../utils";

registerLocale("fr", fr);
setDefaultLocale("fr");

const primaryColor = process.env.REACT_APP_PRIMARY_COLOR;

const Wrapper = styled.div`
  width: 600px;
  @media (max-width: 1023px) {
    width: 100%;
  }
  @media (min-width: 1025px) and (max-width: 1240px) {
    width: 640px;
  }
  @media (min-width: 1200px) and (max-width: 1440px) {
    width: 480px;
  }
  @media (min-width: 1720px) and (max-width: 1919px) {
    width: 720px;
  }
  @media (min-width: 1920px) {
    width: 800px;
  }
`;
const Container = styled.div`
  width: 100%;
  background-color: #dfecfd;
  padding-bottom: 0.5rem;

  .react-datepicker-wrapper {
    display: block;
  }
  input {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    -webkit-font-feature-settings: "tnum";
    font-feature-settings: "tnum", "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 6.5px 11px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.65);
    line-height: 1.5715 !important;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .react-datepicker {
    font-family: "Lato", sans-serif;
    color: #414141;
    font-size: 0.8rem;
    background-color: #fff;
    border: 1px solid #d3d3d3;
    border-radius: 1rem;
    overflow: hidden;
  }
  .react-datepicker__navigation--next {
    border-left-color: #ccc;
  }
  .react-datepicker__month-container .react-datepicker__header {
    border-bottom: 1px solid #d3d3d3;
    border-top-left-radius: 1rem;
    border-top-right-radius: 0;
  }
  .react-datepicker__time-container .react-datepicker__header {
    border-bottom: 1px solid #d3d3d3;
    border-top-left-radius: 0;
    border-top-right-radius: 1rem;
  }
  .react-datepicker__day--today {
    color: ${primaryColor};
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected {
    background-color: ${primaryColor};
    color: #fff;
  }
  .react-datepicker__day--in-range {
    background-color: #eb7c7c;
    color: #fff;
  }
`;
const FormWrapper = styled.div`
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 0.5rem 0.8rem;
  border-radius: 0.5rem;
  color: #fff;
  font-size: 0.75rem;
  font-family: "Lato", sans-serif;
  a {
    color: #000 !important;
  }
`;
const SuccessWrapper = styled.div`
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 2rem 1.4rem;
  border-radius: 1rem;
  color: #fff;
  font-size: 0.8rem;
  font-family: "Lato", sans-serif;
  @media screen and (min-width: 1025px) and (max-width: 1440px) {
    padding: 0.5rem 0.8rem;
  }
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  @media (max-width: 450px) {
    flex-direction: column;
    align-items: stretch;
  }
`;
const RowForm = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 450px) {
    flex-direction: column;
    align-items: stretch;
  }
`;
const Col = styled.div`
  flex: 1;
  @media (max-width: 450px) {
    label {
      display: block;
      width: 100%;
    }
    input {
      width: 100%;
    }
  }
`;
const ColOptin = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  @media (max-width: 450px) {
    label {
      display: block;
      width: 100%;
    }
    input {
      width: 100%;
    }
  }
`;
const ColAction = styled.div`
  width: 120px;
  flex: none;
  margin-top: 0.5rem;
  @media (max-width: 450px) {
    width: 100%;
    flex: 1;
  }
`;
const ColInfo = styled.div`
  color: #222;
  font-weight: bold;
  text-align: center;
  font-size: 1.1rem;
  line-height: 1.4rem;
  @media (max-width: 450px) {
    font-size: 1rem;
    margin: 0 0 0.5rem 0;
  }
`;
const ColForm = styled.div`
  margin: 0.5rem;
  @media (max-width: 450px) {
    margin: 0 1rem 2rem 1rem;
  }
  @media screen and (min-width: 1025px) and (max-width: 1440px) {
    line-height: 1rem;
    font-size: 0.8rem;
    input {
      height: 32px;
      font-size: 0.8rem;
      padding: 0.5rem;
    }
  }
`;
const Error = styled.div`
  color: #efe800;
  font-size: 0.8rem;
  line-height: 1rem;
  padding-bottom: 3px;
`;
const Optin = styled.label`
  display: flex !important;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
`;
const Checkbox = styled.div`
  padding-top: 4px;
  flex: none;
  width: 24px;
  input {
    height: auto;
    width: auto;
  }
`;
const Button = styled.button`
  width: 100%;
  flex: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${primaryColor};
  border-radius: 6px;
  height: 32px;
  color: #ffffff;
  text-decoration: none;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.9rem;
  border: none;
  outline: 0 none;
  cursor: pointer;
  line-height: 32px;
  margin: 0 auto;
  &:hover {
    background-color: #000000;
    color: #ffffff !important;
  }
  &:disabled {
    color: #ccc;
    background-color: #111;
  }
  @media screen and (min-width: 451px) and (max-width: 1024px) {
    margin: 8px auto 0 auto;
  }
`;
const ButtonSuccess = styled.button`
  width: 80px;
  flex: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${primaryColor};
  border-radius: 6px;
  height: 48px;
  color: #ffffff;
  font-weight: 700;
  font-size: 1rem;
  border: none;
  outline: 0 none;
  cursor: pointer;
  line-height: 48px;
  margin: 10px auto;
  &:hover {
    background-color: #000000;
    color: #ffffff !important;
  }
`;
const Title = styled.p`
  margin: 0 0 0.5rem 0 !important;
  font-size: 1.2rem;
  line-height: 1.2rem;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  background-color: #000;
  color: #fff;
  padding: 8px;
  margin: 1rem 0;
  @media (max-width: 450px) {
    font-size: 1.2rem;
    line-height: 1.2rem;
    margin: 0.5rem 0;
  }
`;
const TitleSuccess = styled.p`
  margin: 0;
  font-size: 1.7rem;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  @media screen and (min-width: 1025px) and (max-width: 1440px) {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }
`;

const ValidationMessage = ({ message, prune }) => {
  if (prune === false && message) return <Error>{message}</Error>;

  return null;
};

const ValidationMessageApi = ({ message }) => {
  return <Error>{message}</Error>;
};

const regexTel = /^0[1-9]{1}[0-9]{8}$/g;

const initialState = {
  success: false,
  prune: true,
  loading: false,
  jour: null,
  nom: null,
  tel: null,
  optin: false,
  errors: {
    jour: "",
    nom: "",
    tel: "",
    optin: "",
    api: "",
  },
};

const reducer = (state, action) => {
  let local = { ...state };

  switch (action.type) {
    case "VALIDATE":
      local.loading = true;
      local.prune = false;
      local.errors = initialState.errors;
      local.errors.jour =
        local.jour === null ? "Veuillez choisir la date de déménagement" : "";
      local.errors.nom =
        local.nom === null || local.nom === "" ? "Veuillez saisir le nom" : "";
      if (local.tel === null || local.tel === "") {
        local.errors.tel = "Veuillez saisir le numéro de téléphone";
      } else if (local.tel.match(regexTel) === null) {
        local.errors.tel = "Veuillez saisir un numéro de téléphone valide";
      } else {
        local.errors.tel = "";
      }
      local.errors.optin =
        local.optin === false ? "Vous devez accepter d'être rappelé" : "";
      break;
    case "UPDATE_JOUR":
      local.jour = action.payload;
      local.errors.api = "";
      if (local.jour) {
        local.errors.jour = "";
      }
      break;
    case "UPDATE_NOM":
      local.nom = action.payload;
      local.errors.api = "";
      if (local.nom !== "") {
        local.errors.nom = "";
      }
      break;
    case "UPDATE_TEL":
      local.tel = action.payload;
      local.errors.api = "";
      if (local.tel !== "") {
        local.errors.tel = "";
      }
      break;
    case "UPDATE_OPTIN":
      local.optin = action.payload;
      if (action.payload === true) {
        local.errors.optin = "";
      }
      local.errors.api = "";
      break;
    case "LOADING":
      local.loading = true;
      break;
    case "LOADING_FINISHED":
      local.loading = false;
      break;
    case "SUCCESS":
      local.success = true;
      break;
    case "RESET":
      local = initialState;
      break;
    case "ERROR":
      local.errors = action.payload.errors;
      local.loading = false;
      break;
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
  return local;
};

const Rappel = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const handleSubmit = () => {
    if (state.loading === true) {
      return;
    }

    dispatch({ type: "VALIDATE" });

    if (
      state.optin === false ||
      state.nom === "" ||
      state.nom === null ||
      state.tel === "" ||
      state.tel === null ||
      state.jour === null
    ) {
      dispatch({ type: "LOADING_FINISHED" });
      return;
    }

    const data = {
      tel: state.tel,
      nom: state.nom,
      jour: format(state.jour, "yyyy-MM-dd"),
      optin: state.optin,
      site_web: "loclyon",
      device: getDeviceType(),
    };

    let instance = axios.create({
      baseURL: process.env.REACT_CLU_API_HOST,
      timeout: 10000,
    });

    instance
      .request({
        url: "smljechange",
        method: "post",
        data,
      })
      .then((response) => {
        if (response.data.error || !response.data.data.info) {
          let errors = {};
          errors.api = response.data.data.tel
            ? response.data.data.tel
            : "Une erreur est survenue, veuillez réessayer.";
          dispatch({
            type: "ERROR",
            payload: {
              errors,
            },
          });
        } else {
          dispatch({ type: "SUCCESS" });
        }
      })
      .catch((err) => {
        let errors = {};
        errors.api = "Une erreur est survenue, veuillez réessayer.";
        dispatch({
          type: "ERROR",
          payload: {
            errors,
          },
        });
      });
  };

  const handleSuccessOk = () => {
    dispatch({ type: "RESET" });
  };

  return (
    <Wrapper className="dms-rappel">
      <Container>
        <ColInfo>
          <Title>Vous déménagez{"\u00A0"}?</Title>
          Économisez jusqu'à 300€ par an sur vos factures sans rien faire
          {"\u00A0"}!{"\u00A0"}Énergie, Télécom, assurances, crédit...
        </ColInfo>
        <ColForm>
          {state.success === true ? (
            <SuccessWrapper style={{ textAlign: "center" }}>
              <TitleSuccess>Merci{"\u00A0"}!</TitleSuccess>
              Notre partenaire exclusif Papernest va prendre contact avec vous
              pour vous aider à économiser{"\u00A0"}!
              <br />
              <ButtonSuccess onClick={handleSuccessOk}>
                OK {state.message_api}
              </ButtonSuccess>
            </SuccessWrapper>
          ) : (
            <FormWrapper>
              <RowForm>
                <Col>
                  <label>
                    Date d'emménagement
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={state.jour}
                      onChange={(val, e) => {
                        if (e && typeof e.preventDefault === "function") {
                          e.preventDefault();
                        }
                        dispatch({ type: "UPDATE_JOUR", payload: val });
                      }}
                      minDate={new Date()}
                    />
                  </label>
                </Col>
                <Col>
                  <label>
                    Nom
                    <input
                      type="text"
                      name="nom"
                      onChange={(e) => {
                        dispatch({
                          type: "UPDATE_NOM",
                          payload: e.target.value,
                        });
                      }}
                    />
                  </label>
                </Col>
                <Col>
                  <label>
                    Téléphone
                    <input
                      type="text"
                      name="tel"
                      onChange={(e) => {
                        dispatch({
                          type: "UPDATE_TEL",
                          payload: e.target.value,
                        });
                      }}
                    />
                  </label>
                </Col>
              </RowForm>
              <ValidationMessage
                message={state.errors.jour}
                prune={state.prune}
              />
              <ValidationMessage
                message={state.errors.nom}
                prune={state.prune}
              />
              <ValidationMessage
                message={state.errors.tel}
                prune={state.prune}
              />
              <Row>
                <ColOptin>
                  <Optin>
                    <Checkbox>
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          dispatch({
                            type: "UPDATE_OPTIN",
                            payload: e.target.checked,
                          });
                        }}
                      />
                    </Checkbox>
                    <div>
                      J'accepte d'être contacté par notre partenaire
                      déménagement.{" "}
                      {/* <a
                        href="/conditions-dutilisations/#formulaire-demenagement"
                        target="_blank"
                      >
                        Voir nos conditions d'utilisations.
                      </a> */}
                    </div>
                  </Optin>
                  <ValidationMessage
                    message={state.errors.optin}
                    prune={state.prune}
                  />
                  <ValidationMessageApi message={state.errors.api} />
                </ColOptin>
                <ColAction>
                  <Button disabled={state.loading} onClick={handleSubmit}>
                    <Loader size={24} visible={state.loading} /> Je valide
                  </Button>
                </ColAction>
              </Row>
            </FormWrapper>
          )}
        </ColForm>
      </Container>
    </Wrapper>
  );
};

export default Rappel;
