import React from "react";
import { format } from "date-fns";

const SearchStateContext = React.createContext();
const SearchDispatchContext = React.createContext();

//@TODO Retirer les paramètres inutiles
const initialState = {
  status: "prune", // prune, searching, ranging
  fromDate: null,
  toDate: null,
  proximite_km: "0.1",
  lat_depart: "",
  lng_depart: "",
  vca_depart: "",
  lat_retour: "",
  lng_retour: "",
  vca_retour: "",
  administrative_area_level_1: "",
  data_algolia_dep: "",
  data_algolia_ret: "",
  dhm_depart: "",
  date_depart: "",
  heure_depart: "",
  min_depart: "",
  dhm_retour: "",
  date_retour: "",
  heure_retour: "",
  min_retour: "",
  forfait_km: "100",
  carType: "vu",
  siteweb: "clulyon",
  loueurs: [
    "TRUCHE",
    "AVIS",
    "GETAROUND",
    "CITY-DROP",
    "HERTZ",
    "EUROPCAR",
    "FRANCE-CARS",
    "ADA",
    "SIXT",
    "RENTACAR",
    "CARGO",
    "OUICAR",
    "FLYCAR",
    "ENTERPRISE",
    "LUCKYLOC-DRIIVEME",
    "DRIIVEME",
    "TRAVELCAR",
    "LUCKYLOC",
    "LOCATION U",
  ],
  partenaireId: 0,
};

function searchReducer(state, action) {
  let local = { ...state };

  switch (action.type) {
    case "update": {
      if (local.hasOwnProperty(action.payload.key))
        local[action.payload.key] = action.payload.value;
      break;
    }

    case "update-range": {
      local.proximite_km = action.payload;
      local.status = "ranging";
      break;
    }

    case "submit": {
      const {
        fromDate,
        toDate,
        fromAlgolia,
        toAlgolia,
        proximite_km,
        partenaireId,
      } = action.payload;
      local.fromDate = fromDate;
      local.toDate = toDate;

      local.lat_depart = fromAlgolia.latlng.lat;
      local.lng_depart = fromAlgolia.latlng.lng;
      local.vca_depart = fromAlgolia.value;
      local.lat_retour = toAlgolia.latlng.lat;
      local.lng_retour = toAlgolia.latlng.lng;
      local.vca_retour = toAlgolia.value;
      local.administrative_area_level_1 = fromAlgolia.administrative;
      local.data_algolia_dep = JSON.stringify({
        city: fromAlgolia.city ? fromAlgolia.city : fromAlgolia.name,
        type: fromAlgolia.type ? fromAlgolia.type : "city",
      });
      local.data_algolia_ret = JSON.stringify({
        city: toAlgolia.city ? toAlgolia.city : toAlgolia.name,
        type: toAlgolia.type ? toAlgolia.type : "city",
      });

      local.dhm_depart = format(fromDate, "dd/MM/yyyy k:mm");
      local.date_depart = format(fromDate, "dd/MM/yyyy");
      local.heure_depart = format(fromDate, "kk");
      local.min_depart = format(fromDate, "mm");
      local.dhm_retour = format(toDate, "dd/MM/yyyy k:mm");
      local.date_retour = format(toDate, "dd/MM/yyyy");
      local.heure_retour = format(toDate, "kk");
      local.min_retour = format(toDate, "mm");

      if (proximite_km !== undefined) {
        local.proximite_km = proximite_km;
      }
      if (partenaireId !== undefined) {
        local.partenaireId = partenaireId;
      }

      local.status = "searching";
      break;
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }

  return local;
}

function SearchProvider({ children }) {
  const [state, dispatch] = React.useReducer(searchReducer, initialState);

  return (
    <SearchStateContext.Provider value={state}>
      <SearchDispatchContext.Provider value={dispatch}>
        {children}
      </SearchDispatchContext.Provider>
    </SearchStateContext.Provider>
  );
}

function useSearchState() {
  const context = React.useContext(SearchStateContext);
  if (context === undefined) {
    throw new Error("useSearchState must be used within a SearchProvider");
  }
  return context;
}

function useSearchDispatch() {
  const context = React.useContext(SearchDispatchContext);
  if (context === undefined) {
    throw new Error("useSearchDispatch must be used within a SearchProvider");
  }
  return context;
}

export { SearchProvider, useSearchState, useSearchDispatch };
